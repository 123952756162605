import React from "react";
import format from "date-fns/format";
import DropdownComponent from "../_common/dropdown";


const StocksTable = (props) => {
  return (

    <div className="table-wrapper px-3 pt-3">
     
    <div className="table-wrapper-scroll-y table-scrollbar">
      <div className="table-own table-responsive">
        <table className="table table-admin">
          <thead className="table-head">
            <tr>
              <th
                className={`sort-header cursor ${props?.sortObj?.sortVal === "name" ? "active" : ""
                  } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
               
              >
                <span>S. No.</span>{" "}
               
              </th>
              <th
                className={`sort-header cursor ${props?.sortObj?.sortVal === "name" ? "active" : ""
                  } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                onClick={() => props.arraySorting("name")}
              >
                <span>Product Name</span>{" "}
                {props?.sortObj?.sortType === "aesc" && (
                  <i className="fas fa-caret-down"></i>
                )}
                {props?.sortObj?.sortType === "desc" && (
                  <i className="fas fa-caret-up"></i>
                )}
              </th>
              <th className="text-center">
                <span>Product Image</span>
              </th>
              
            
              <th
                className={`sort-header text-center cursor ${props?.sortObj?.sortVal === "created" ? "active" : ""
                  }
              ${props?.sortObj?.sorType ? "asec" : "desc"}
              `}
                onClick={() => props.arraySorting("stockQuantity")}
              >
                <span>Quantity</span>
                {props?.sortObj?.sortType === "asec" && (
                  <i className="fas fa-caret-down"></i>
                )}
                {props?.sortObj?.sortType === "desc" && (
                  <i className="fas fa-caret-up"></i>
                )}
              </th>
              <th className="text-center">
                <span>Status</span>
              </th>
              
              <th className="text-center">
                <span>Action</span>
              </th>
            </tr>
          </thead>
          <tbody className="table-body">
            {props.dataList.map((data, i) => {
              let address = data?.storeNumber + ", " + data?.street;
              if (data?.address) {
                address = address + ", " + data?.address;
              }
              address = address + ", " + data?.city;
              return (
                <tr key={data?.storeId || "N/A"}>
                  <td>{i+1}</td>

                  <td>{data?.name || "N/A"}</td>
                  <td><div className="table-product-img text-center">
                      <img src={data?.image} width={30} alt="img"/>
                      
                      </div></td>
                 
                 
                 <td className="text-center">{data?.stockQuantity ?? "N/A"}</td>
                  

                  <td className="text-center">
                    {data?.isActive ? (
                      <span className="table-isactive">Active</span>
                    ) : (
                      <span className="table-isinactive">Inactive</span>
                    )}
                  </td>
                 
                  <td className="nowrap text-center">
                  
                  <div>
                      <DropdownComponent                      
                      list={[
                          {
                        text:'Edit',
                        onClick:()=>props?.editData(data)
                      },
                      {
                        text:data?.isActive?'deactivate':'activate',
                        onClick:()=>props?.toggleData(data)
                      },
                      {
                        text:'Delete',
                        onClick:()=>props?.deleteData(data)
                      }

                      ]
                    }
                      />
 
  </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  </div>
    // <div className="table-wrapper px-3 pt-3">
    //   <div className="d-flex justify-content-between pb-1 align-items-end">
    //     <div className="table-type">Stock transactions</div>
    //   </div>
    //   <div className="table-wrapper-scroll-y table-scrollbar">
    //     <div className="table-own table-responsive">
    //       <table className="table table-striped table-hover table-admin">
    //         <thead className="table-head">
    //           <tr>
    //             <th>ID</th>
    //             <th>Stock Edited</th>
    //             <th>Date</th>
    //             <th>Time</th>
    //             <th>Notes</th>
    //             <th>Users</th>
    //           </tr>
    //         </thead>
    //         <tbody className="table-body">
    //           {props?.dataList &&
    //             props?.dataList.map((item, index) => (
    //               <tr key={index}>
    //                 <td>{index+1}</td>
    //                 <td>{item?.stockQuantity || "N/A"}</td>
    //                 <td>
    //                  {item?.timestamp?format(new Date(item?.timestamp), "dd/MM/yyyy"):"N/A"}
    //                 </td>
    //                 <td>
    //                 {item?.timestamp?format(new Date(item?.timestamp), "hh:mm a"):"N/A"}

    //                 </td>
    //                 <td>{item?.notes || "N/A"}</td>
    //                 <td>{item?.adminUser?.email || "N/A"}</td>
    //               </tr>
    //             ))}
    //         </tbody>
    //       </table>
    //     </div>
    //   </div>
    // </div>
  );
};

export default StocksTable;
