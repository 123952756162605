/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import InputComponent from "../_common/inputComponent";



const AddProductModal = (props) => {
  const [name, setName] = useState("");  
  const [nameErr, setNameErr] = useState(false);
  const [description, setDescription] = useState("");  
  const [descriptionErr, setDescriptionEr] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [imageUrl, setImageUrl] = useState('');
 


  useEffect(() => {
    setPropsToState(); // eslint-disable-next-line
  }, [props?.data]);

  const setPropsToState = () => {
    setName(props?.data?.name);
    setDescription(props?.data?.description);
    setImageUrl(props?.data?.image)
  
  };

  const handleValidation = () => {
    let flag = true;
    if (!name) {
      flag = false;
      setNameErr(true);
    }

    if (!description) {
        flag = false;
        setDescriptionEr(true);
      }

      
    if (!imageUrl) {
        flag = false;
        setError('File is required');
      }

    
    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await handleValidation()) {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        if(file){
          formData.append('file', file);

        }
        props.closeModal(formData);
    }
  };


  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const fileType = selectedFile.type;
      const fileSize = selectedFile.size / 1024 / 1024; // Convert to MB

      if (fileType === 'image/png' || fileType === 'image/jpeg') {
        if (fileSize > 5) {
          setError('File size should not exceed 5MB.');
          setFile(null);
          setImageUrl('');
        } else {
          const img = new Image();
          const reader = new FileReader();

          reader.onload = (e) => {
            img.src = e.target.result;
            img.onload = () => {
              if (img.width === img.height || true) {
                setFile(selectedFile);
                setImageUrl(e.target.result); // Set the image URL for display
                setError('');
              } else {
                setError('Uploaded image must be square (equal width and height).');
                setFile(null);
                setImageUrl('');
              }
            };
          };

          reader.readAsDataURL(selectedFile);
        }
      } else {
        setError('Only .png, .jpg, and .jpeg files are allowed.');
        setFile(null);
        setImageUrl('');
      }
    }
  };

 

 

 


  return (
    <Modal
      open={props.open}
      onClose={() => props.closeModal()}
      center
      classNames={{ modal: "admin-modal-new admin-modal" }}
    >
      <div>
        <p className="admin-banner-new px-3">{props?.edit ? "Edit" : "Add"} Product</p>
      </div>
      <form onSubmit={handleSubmit} className="form-own px-3">
        <div className="row">
          <div className="col-12">
            <div className="form-own-group">
             
              <InputComponent
                placeholder="Title"
                label={
                  <>
                    Product Name <span className="color-red">*</span>
                  </>
                }
                type="text"
                name="name"
                theme="white"
                value={name}
                handleCallback={(val) => {
                  setName(val);
                }}
              />
              <div className="error-text">
                {!name && nameErr && (
                  <span>Name is required</span>
                )}
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="form-own-group">
             
              <InputComponent
                placeholder="Description"
                label={
                  <>
                    Product Description <span className="color-red">*</span>
                  </>
                }
                type="text"
                name="name"
                theme="white"
                value={description}
                handleCallback={(val) => {
                  setDescription(val);
                }}
              />
              <div className="error-text">
                {!description && descriptionErr && (
                  <span>Description is required</span>
                )}
              </div>
            </div>
          </div>

          <div className="col-12">

            <div className="d-flex flex-wrap img-upload-wrapper">

                <div className="img-upload-container">

                    <div className="upload-area text-center">
                      {imageUrl &&
                      <div className="col-12">
                    <img src={imageUrl} alt="Selected" width={50} />
                    </div>
                    
                    }
                        <p className="d-inline-block upload-btn btn btn-grey">+ {imageUrl?'Edit':'Add'} File</p>
                <input
        type="file"
        accept=".png,.jpg,.jpeg"
        onChange={handleFileChange}
      />
      </div>

      



      
                </div>

                <div className="upload-notes-wrapper flex-1 ml-3 text-color-primary">
                    <h4>Important Notes <span className="color-red">*</span></h4>
                    <p className="mb-1">1. Image size up to 5MB.</p>
                    <p className="mb-1">2. Upload square shape image.</p>
                    <p className="mb-1">3. Upload .jpg, .png and .jpeg image only.</p>

                </div>

               
            </div>
            {error && <p className="color-red font-13">{error}</p>}
            </div>
         
        </div>
        <div className="admin-btn text-right justify-content-end">
          <button
            onClick={() => props.closeModal()}
            className="btn btn-new btn-grey mr-2"
            type="button"
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-new primary-btn ml-2">
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddProductModal;
