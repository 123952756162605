/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Header from "../template/_common/header";
import SidebarComponent from "../template/admin/sidebarComponent";
import StoresTable from "../template/admin/storesTable";
import SidebarArrow from "../template/_common/sidebarArrow";
import { globalAlert, globalLoader } from "../actions/commonActions";
import AddStoreModal from "../template/admin/addStoreModal";
import { MAP_SHOW_LIST } from "../actionTypes/types";
import { Select, FormControl, MenuItem, InputLabel } from "@material-ui/core";
import {
  getStoreList,
  viewStoreApi,
  addStoreApi,
  updateStoreApi,
  deleteStoreApi,
  addStockApi,
  getStoreDownloadList,
  toggleStoreApi,
  adminZonerListApi
} from "./_actions";
import ViewQrModal from "../template/admin/viewQrModal";
import ConfirmationModal from "../template/_common/confirmModal";
import PaginationOwn from "../template/_common/ownPagination";
import { compareValues, exportData, logOut } from "../_shared/commonFunction";
import { Helmet } from "react-helmet";
import InputComponent from "../template/_common/inputComponent";
import ManageStockModal from "../template/admin/manageStockModal";
import arraySort from 'array-sort';

const ManageStore = (props) => {
  const [navbarToggle, setNavbarToggle] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  const [openAddStoreModal, setopenAddStoreModal] = useState(false);
  const [openStockModal, setOpenStockModal] = useState(false);
  const [editDataIs, setEditDataIs] = useState(false);
  const [openViewStore, setOpenViewStore] = useState(false);
  const [currentViewData, setCurrentViewData] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [confirmaModalOpen, setConfirmaModalOpen] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [searchVal, setSearchVal] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDataList, setCurrentDataList] = useState([]);
  const [sortObj, setSortObj] = useState({});
  const [currentDataSorted, setCurrentDataSorted] = useState([]);
  const [totalCount, setTotalCount] = useState(false);
  const [allowView, setAllowView] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [toggleInfo, setToggleInfo] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [zoneList, setZoneList] = useState(null);
  const [zoneFilterList, setZoneFilterList] = useState(null);
  const [zoneValue, setZoneValue] = useState('All');

  const [hiddenOnMap, setHiddenOnMap] = useState('all')

  const handleSidebar = (data) => {
    setNavbarToggle(data);
  };

  useEffect(() => {
    callBasicApi(true); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    callBasicApi();
  }, [zoneValue]);

  const callBasicApi = async (zoneApiCall = null) => {
    try {
      globalLoader(true);

      if (zoneApiCall) {
        const res = await adminZonerListApi();
        if (res?.data?.status === 200) {
          setZoneFilterList(res?.data?.data?.zones);
          setZoneList(res?.data?.data?.zones?.filter(x => x?.value?.toLowerCase() !== "all"));
        }
      }
      const query = `?zone=${zoneValue}`
      let res = await getStoreList(query);
      if (res?.data?.status === 200) {
        setAllowView(true);
        setSearchVal('')
        // setDataList(res?.data?.data?.storesList);
        let currData = res?.data?.data?.storesList?.map(x => {
          return {
            ...x,
            stockQuantity: Number(x?.stockQuantity || 0),
          };
        });

        setDataList(currData);
        setCurrentDataSorted(res?.data?.data?.storesList);
        !searchVal && setTotalCount(res?.data?.data?.storesList.length);
      } else {
        globalAlert("error", res?.data?.message);
      }
      globalLoader(false);
    } catch (error) {
      globalAlert("error", error?.response?.data?.message);

      if (error?.response?.data?.status === 401) {
        logOut(props?.history, "/admin/login");
      }
      globalLoader(false);
    }
  };

  const closeAddStoreModal = async (val = null) => {
    if (val) {
      globalLoader(true);
      let res;
      if (editDataIs) {
        res = await updateStoreApi(val, currentData?.storeId);
      } else {
        res = await addStoreApi(val);
      }

      if (res?.data?.status === 200) {
        globalAlert("success", res?.data?.message);
        if (!editDataIs) {
          setSearchVal("");
        }
        
        setEditDataIs(false);
        setCurrentData(null);
        setopenAddStoreModal(false);
        callBasicApi();
      } else {
        globalAlert("error", res?.data?.message);
        globalLoader(false);
      }
    } else {
      setEditDataIs(false);
      setCurrentData(null);
      setopenAddStoreModal(false);
    }
  };

  const editData = (data) => {
    setEditDataIs(true);
    setCurrentData(data);
    setopenAddStoreModal(true);
  };

  const viewQrData = async (data) => {
    globalLoader(true);
    let res = await viewStoreApi(data);
    if (res?.data?.status === 200) {
      setCurrentViewData(res?.data?.data?.orgStore);
      setOpenViewStore(true);
    } else {
      globalAlert("error", res?.data?.message);
    }
    globalLoader(false);
  };

  const confirmModalFunc = async (val) => {
    if (val) {
      globalLoader(true);

      let res;

      if(toggleInfo){
       res = await toggleStoreApi(currentUserId);
      }
      else{
       res= await deleteStoreApi(currentUserId);
      }
      
      if (res?.data?.status === 200) {
        setEditDataIs(false);
        setCurrentData(null);
        setToggleInfo(null);
        setDeleteInfo(null)
        
        if (totalCount === 1) setSearchVal("");
        callBasicApi();
      } else {
        globalAlert("error", res?.data?.message);
        globalLoader(false);
      }
    }
    setConfirmaModalOpen(false);
  };

  const closeStockModal = async (val = null) => {
    if (val) {
      try {
        globalLoader(true);
        let res = await addStockApi(currentViewData?.storeId, val);
        if (res?.data?.status === 200) {
          setEditDataIs(false);
          setCurrentViewData(null);
          
          callBasicApi();
        } else {
          globalAlert("error", res?.data?.message);
          globalLoader(false);
        }
      } catch { 
        globalLoader(false);
      }
    }
    setOpenStockModal(false);
  };

  const handleFilterChangeZone = (ev) => {
    if (ev?.target?.value) {
      setZoneValue(ev?.target?.value)
    }
  };


  const downloadFile =async ()=>{

    try{
      globalLoader(true);
      let res = await getStoreDownloadList();

      if (res?.status === 200) {    
        if(!res.data){
          globalAlert("error", 'No data found');
        }   
        else{
          exportData(res.data,  `Store_${new Date().getTime()}.csv`, 'text/csv');
        }
      } else {
        globalAlert("error", res?.message);
      }
      globalLoader(false);
    }
    catch{
      globalAlert("error", 'Something went wrong');
      globalLoader(false);
    }

  }

  const deleteData = (data) => {
    setCurrentUserId(data?.storeId);
    let obj = [`Store Name : ${data?.storeName}`, `Address : ${data?.address}`];
    setDeleteInfo(obj);
    setConfirmaModalOpen(true);
  };

  const toggleData = (data) => {
    setCurrentUserId(data?.storeId);
    let obj = [`Store Name : ${data?.storeName}`, `Address : ${data?.address}`];
    setToggleInfo(obj);
    setCurrentData(data)
    setConfirmaModalOpen(true);
  };

  const manageStockData = (data) => {
    setCurrentUserId(data?.storeId); // eslint-disable-next-line
    props?.history?.push({
      pathname: `/admin/manage-stores/${data?.storeId}`,
      state: { data },
    });
    // setOpenStockModal(true)
    // setCurrentViewData(data)
  };

  useEffect(() => {

    filteredDataFunc();

  }, [pageSize, currentPage, sortObj, currentDataSorted, dataList, hiddenOnMap, searchValue]);

  const filteredDataFunc = (dataArray = null, localPage = null) => {
    let arr = [...dataList];
    let filterArr = [];
    let filteredData = [];

    if (dataArray) {
      arr = [...dataArray]
    }
    else {
      arr = arraySort(arr, compareValues(sortObj?.sortVal), { reverse: sortObj?.sortType === 'desc' ? true : false });
    }
    if (searchValue.length > 0) {
      filterArr = arr.filter((data) => {
        let storeName = data?.storeName.toLowerCase();
        let val = searchValue.toLowerCase();
        let address = (data?.searchAddress || data?.address).toLowerCase();
        if (storeName.includes(val) || address.includes(val)) {
          return data;
        } else {
          return null;
        }
      });
    } else {
      filterArr = arr;
    }

    if (filterArr?.length > 0) {
      if (hiddenOnMap === 'yes') {
        filterArr = filterArr.filter(ele => ele?.hideOnMap)


      }
      else if (hiddenOnMap === 'no') {
        filterArr = filterArr.filter(ele => !ele?.hideOnMap)
      }
    }




    if (filterArr && filterArr.length > 0) {
      setTotalCount(filterArr.length);
      for (
        let i = (localPage || currentPage) * pageSize - pageSize;
        i < (localPage || currentPage) * pageSize && i < filterArr.length;
        i++
      ) {
        filteredData.push(filterArr[i]);
      }
    }

    setCurrentDataList(filteredData);
  };

  const changePageSizeFunc = async (val) => {
    
    setPageSize(val.target.value);
    setCurrentPage(1);
    setSortObj(null);
  };

  const searchFilter = (ev) => {
    ev.preventDefault();
    let arr = [...dataList];
    let filterArr = [];

    arr.forEach(
      (data) => {
        let storeName = data?.storeName.toLowerCase();
        let val = searchValue.toLowerCase();
        let address = (data?.searchAddress || data?.address).toLowerCase();



        if (storeName.includes(val) || address.includes(val)) {

          filterArr.push(data)
        }

      }


    );

    setTotalCount(filterArr.length);
    
    setCurrentDataList(filterArr);
    filteredDataFunc(filterArr)
    setCurrentPage(1);
    setSortObj(null);
    
  };

  const goToPageFunc = (val, data) => {
    
    if (data === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (
      data === "next" &&
      currentPage < Math.ceil(dataList?.length / pageSize)
    ) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(val?.target?.value);
    }
  };

  const arraySorting = (val) => {
    let arr = [...dataList];
    let sortedArr;
    let sortType = "aesc";
    if (val === sortObj?.sortVal && sortObj?.sortType === "aesc") {
      sortType = "desc";
    }
    sortedArr = arraySort(arr, compareValues(val), { reverse: sortType === 'desc' ? true : false });
  
    setCurrentPage(1);
    setSortObj({
      sortVal: val,
      sortType: sortType,
    });
    filteredDataFunc(sortedArr, 1);
  };


  const hideOnMapFunc = (val) => {
    setHiddenOnMap(val)
    setCurrentPage(1);
    filteredDataFunc(dataList, 1, val)
  }
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=1200, initial-scale=1" />
      </Helmet>
      {allowView && (
        <div className="admin-portal">
          <Header roleType="admin" />
          <div className={`${navbarToggle ? "active" : ""} wrapper`}>
            <SidebarComponent
              navbarToggle={navbarToggle}
              // active={props.match.path}
              menu="manageVenue"
              subMenu='manage-stores'
            />
            <div className="main">
              <div className="main-wrapper">
                <div>
                  <p className="admin-banner">Stores</p>
                </div>
                <SidebarArrow handleSidebarToggle={handleSidebar} />
                <div>

                  <div className="custom-search-wrapper px-3 pt-3">

                    {/* <div className="input-wrapper position-relative">
                      <input className="form-control" value={searchValue} onChange={(ev) => setSearchValue(ev.target.value)} placeholder="Search" onKeyDown={handleKeyDown} />
                      <i className="fas fa-search search-icon" />
                    </div> */}

                    <div className=" d-flex flex-wrap position-relative admin-controls">
                      <div className="input-wrapper">
                        <form
                          onSubmit={(ev) => searchFilter(ev)}
                          className="admin-search"
                        >
                          <div className="search-wrapper">
                            <InputComponent
                              placeholder="Search Venue"
                              label="Search Venue"
                              type="text"
                              name="search"
                              theme="admin"
                              value={searchValue}
                              handleCallback={(val) => {
                                setSearchValue(val);
                              }}
                            />
                          </div>
                          <button type="submit" className="btn secondary-btn">
                            Go
                          </button>
                        </form>
                      </div>
                      {zoneFilterList?.length > 0 &&

<div className="show-item-page filter-type form-own ml-3 form-report-group">
  <FormControl variant="outlined">
    <InputLabel id="show_per_page">Venue Zone</InputLabel>
    <Select
      labelId="show_per_page"
      id="demo-simple-select-outlined"
      value={zoneValue || ''}
      onChange={handleFilterChangeZone}
      label="Venue Zone"
      name="pageSize"
    >
      {
        zoneFilterList?.length > 0 &&
        zoneFilterList?.map((data, index) => (
          <MenuItem value={data.value} key={index}>
            {data.label}
          </MenuItem>
        ))}
    </Select>
  </FormControl>
</div>
}
                      <div className="pl-3 show-item-page hidden-on-map-select">

                        <FormControl variant="outlined">
                          <InputLabel id="show_per_page2">Hidden on map</InputLabel>

                          <Select
                            labelId="show_per_page2"
                            value={hiddenOnMap}
                            onChange={(ev) => setHiddenOnMap?.(ev.target?.value)}
                            label="Hidden on map"
                            name="pageSize"
                            className="small-select"
                          >
                            {MAP_SHOW_LIST.map((data, index) => (
                              <MenuItem value={data.value} key={index}>
                                {data.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        {/* {MAP_SHOW_LIST.map((i) => {
            return (
              <div key={i.id} className="radio ml-2 cursor" id="gender">
                <input
                  id={i.id}
                  className="cursor"
                  type="radio"
                  value={i.value}
                  name="map"
                  checked={props?.hiddenOnMap === i.value}
                  onChange={(ev) => props?.setHiddenOnMap?.(ev.target?.value)}
                  autoComplete="off"
                />
                <label className="mb-0 cursor" htmlFor={i.id}>
                  {i?.text}
                </label>
              </div>
            );
          })} */}
                      </div>
                    </div>

                  </div>
                  <div className="container-fluid pt-1">
                    <div className="admin-add-new mt-lg-0 d-flex justify-content-end">
                      <button
                        onClick={() => setopenAddStoreModal(true)}
                        className="btn secondary-btn modal-btn"
                      >
                        <i className="fas fa-plus"></i> Add Store
                      </button>
                    </div>
                    <div className="row admin-controls">
                      {dataList?.length > 0 && (
                        <div
                          className="px-3  col-12 pt-3"
                          style={{ borderTop: "2px solid #ebeef1" }}
                        >
                          <PaginationOwn
                            pageSize={pageSize}
                            changePageSize={changePageSizeFunc}
                            goToPage={goToPageFunc}
                            totalCount={totalCount}
                            currentPage={currentPage}
                            applicationType={
                              currentDataList?.length > 1 ? "stores" : "store"
                            }
                          />
                          <div className="d-flex flex-wrap col-12 px-0 my-2 justify-content-start">
                            <button
                              onClick={downloadFile}
                              className="btn secondary-btn modal-btn"
                            >
                              <i className="fas fa-download"></i> Download
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {currentDataList?.length > 0 ? (
                  <StoresTable
                    dataList={currentDataList}
                    arraySorting={arraySorting}
                    basicApiCall={callBasicApi}
                    sortObj={sortObj}
                    viewQrData={viewQrData}
                    editData={editData}
                    deleteData={deleteData}
                    manageStockData={manageStockData}
                    setHiddenOnMap={hideOnMapFunc}
                    hiddenOnMap={hiddenOnMap}
                    toggleData={toggleData}
                  />
                ) : (
                  <div className="px-3 pt-3">
                    <div className="no-data-available">No data available</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {openAddStoreModal && (
        <AddStoreModal
          open={openAddStoreModal}
          closeModal={closeAddStoreModal}
          data={currentData}
          edit={editDataIs}
          zoneList={zoneList}
        />
      )}

      {openViewStore && (
        <ViewQrModal
          open={openViewStore}
          closeModal={() => setOpenViewStore(false)}
          data={currentViewData}
        />
      )}
      {openStockModal && (
        <ManageStockModal
          open={openStockModal}
          closeModal={closeStockModal}
          data={currentViewData}
        />
      )}
      {confirmaModalOpen && (
        <ConfirmationModal
          open={confirmaModalOpen}
          deleteInfo={toggleInfo || deleteInfo}
          description={toggleInfo? `Do you want to ${currentData?.isActivated?'deactivate':'activate'} this store?`:"Do you want to delete this store?"}
          onCloseModal={confirmModalFunc}
        />
      )}
    </>
  );
};

export default ManageStore;
