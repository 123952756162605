import React from "react";
import { CSVLink } from "react-csv";
import InputComponent from "../_common/inputComponent";
import PaginationOwn from "../_common/ownPagination";

const ReportUserTable = (props) => {

  const headers = [
    { label: "Telephone", key: "userMobile" },
    { label: "User Zone", key: "zone" },
    { label: "Total Products Collected", key: "count" },
  ];

  return (
    <>
      <div className="pt-4 admin-controls">
        <form
          onSubmit={(ev) =>
            // eslint-disable-next-line
             props?.searchFunc(ev, props?.searchVal)}
          className="col-12 col-lg-6 admin-search"
        >
          <div className="search-wrapper">
            <InputComponent
              placeholder="Search User"
              label="Search User"
              type="text"
              name="search"
              theme="admin"
              value={props?.searchVal}
              handleCallback={(val) => {
                // eslint-disable-next-line
                props?.setSearchVal(val);
              }}
            />
          </div>
          <button type="submit" className="btn secondary-btn">
            Go
          </button>
        </form>
      </div>
      <div className="report-wrapper px-3 row">
        <div className="col-12">
          <div className="download-btn pb-3 pb-md-4">
            <CSVLink
              className="btn admin-btn secondary-btn"
              data={props?.allReport}
              filename={"user-report.csv"}
              headers={headers}
            >
              Download CSV
            </CSVLink>
            {props?.reportData?.length > 0 && (
              <div className="px-3 col-12 pt-3">
                <PaginationOwn
                  pageSize={props?.pageSize}
                  changePageSize={props?.changePageSize}
                  goToPage={props?.goToPage}
                  totalCount={props?.totalCount}
                  currentPage={props?.currentPage}
                  applicationType={props?.applicationType}
                  paginationType="report"
                />
              </div>
            )}
          </div>
        </div>
        <div className="report-table-wrapper col-6">
          <p className="report-type">Condoms collected per user</p>
          <div className="table-wrapper-scroll-y table-scrollbar">
            <div className="table-responsive table-own">
              <table className="table table-striped table-hover table-admin">
                <thead className="table-head">
                  <tr>
                    <th
                      className={`sort-header cursor ${props?.sortObj?.sortVal === "userMobile" ? "active" : ""
                        } ${props?.sortObj.sortType ? "aesc" : "desc"}`}
                      onClick={() => props?.arraySorting("userMobile")}
                    >
                      <span>Telephone</span>
                      {props?.sortObj?.sortType === "aesc" && (
                        <i className="fas fa-caret-down"></i>
                      )}
                      {props?.sortObj?.sortType === "desc" && (
                        <i className="fas fa-caret-up"></i>
                      )}
                    </th>
                    <th
                      className={`sort-header cursor ${props?.sortObj?.sortVal === "zone" ? "active" : ""
                        } ${props?.sortObj.sortType ? "aesc" : "desc"}`}
                      onClick={() => props?.arraySorting("zone")}
                    >
                      <span>User Zone</span>
                      {props?.sortObj?.sortType === "aesc" && (
                        <i className="fas fa-caret-down"></i>
                      )}
                      {props?.sortObj?.sortType === "desc" && (
                        <i className="fas fa-caret-up"></i>
                      )}
                    </th>
                    <th
                      className={`sort-header cursor ${props?.sortObj.sortVal === "count" ? "active" : ""
                        } ${props?.sortObj.sortType ? "aesc" : "desc"}`}
                      onClick={() => props?.arraySorting("count")}
                    >
                      <span>Total Products Collected</span>
                      {props?.sortObj?.sortType === "aesc" && (
                        <i className="fas fa-caret-down"></i>
                      )}
                      {props?.sortObj?.sortType === "desc" && (
                        <i className="fas fa-caret-up"></i>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {props?.reportData?.map((data, index) => {
                    return (
                      <tr key={index + data.userMobile}>
                        <td>{data.userMobile}</td>
                        <td>{data?.zone}</td>
                        <td>{data.count}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="reports-stats-wrapper col-4">
          <p className="report-type">Totals per date range</p>
          <div className="reports-stats">
            <ul className="list-unstyled">
              <li className="list-group-item-success d-flex justify-content-between report-data">
                <span>Stores Used</span>
                <span className="pr-2"> {props?.totalStats?.storesUesd}</span>
              </li>
              <li className="list-group-item-secondary d-flex justify-content-between report-data">
                <span>Kiosk Used</span>
                <span className="pr-2"> {props?.totalStats?.ivtsUesd}</span>
              </li>
              <li className="list-group-item-warning d-flex justify-content-between report-data">
                <span>Collections</span>
                <span className="pr-2">
                  {" "}
                  {props?.totalStats?.totalCollections}
                </span>
              </li>
              <li className="list-group-item-primary d-flex justify-content-between report-data">
                <span>Users Added</span>
                <span className="pr-2"> {props?.totalStats?.newUsers}</span>
              </li>
              <li className="list-group-item-danger d-flex justify-content-between report-data">
                <span>Users Active</span>
                <span className="pr-2">{props?.totalStats?.activeUsers}</span>
              </li>
              <li className="list-group-item-success d-flex justify-content-between report-data">
                <span>Total Users (All Time)</span>
                <span className="pr-2">{props?.totalStats?.totalUsers}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportUserTable;
